<template>
  <section>
    <!--HEADER-->
    <b-overlay :show="!tripData">
      <trip-view :trip-data="tripData" />
    </b-overlay>

    <hangar-search :loading="tripStepLoading" :trip-step-event="tripStepEvent" @end="val => (paramsHangar.endAt = val)" @start="val => (paramsHangar.startAt = val)" @click:search-hangar="searchHangar()" />

    <b-overlay :show="showLoading" class="my-3" spinner-medium>
      <b-row v-if="!organizationsIds.length">
        <b-col cols="12">
          <b-alert class="m-0" show variant="primary">
            <div class="alert-body text-center">
              <span>{{ $t('alert.hangar.no_hangar_found') }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col md="12">
          <b-card v-for="organization in organizationsIds" :key="organization.id">
            <b-col class="d-flex justify-content-start p-0">
              <div class="col-4 p-0">
                <b-img :src="organization.logoFileUrl ? organization.logoFileUrl : require('@/assets/images/logo/logo.png')" class="product-img" fluid width="300px" />
              </div>
              <div class="col-8 p-0">
                <h2 class="text-underline">
                  <b-link
                    :to="{
                      name: 'provider-details',
                      params: {
                        provider_id: organization.id,
                        trip_id: tripData.id,
                        step_id: tripStep.id,
                        event_id: tripStepEvent.id,
                        airport_id: tripStepEvent.airport.id,
                      },
                    }"
                  >
                    {{ organization.name }}
                  </b-link>
                </h2>
                <p v-html="organization.description"></p>
              </div>
            </b-col>
            <div class="d-flex justify-content-center mb-1">
              <b-button
                v-model="viewHangars[organization.id]"
                v-b-toggle="'collapse_hangar-' + organization.id"
                v-ripple.400="'rgba(220, 193, 129, 0.15)'"
                :pressed="viewHangars.includes('hangar' + organization.id)"
                :variant="`light`"
                class="mx-1"
                size="sm"
                @click="toggleHangarDetails('hangar' + organization.id)"
              >

                {{ viewHangars.includes('hangar' + organization.id) ? $t('trip.hangar.view_less') : $t('trip.hangar.view_hangars') }}
                <font-awesome-icon :icon="viewHangars.includes('hangar' + organization.id) ? 'chevron-down' : 'chevron-up'" class="ml-50" size="sm" />
              </b-button>
            </div>
            <b-collapse :id="'collapse_hangar-' + organization.id">
              <b-card v-for="product in products.filter(item => item.product.organization.id === organization.id)" :key="product.id" class="border">
                <b-col class="d-flex justify-content-start p-0">
                  <div class="col-4 p-0">
                    <b-img
                      :src="
                        product.product.mainVariant.files.length ? product.product.mainVariant.files[0].fileUrls[300] : require('@/assets/images/logo/logo.png')
                      "
                      class="product-img"
                      fluid
                    />
                  </div>
                  <div class="m-0 pr-0 col-8 d-flex flex-wrap align-items-start">
                    <div class="col-12">
                      <div class="d-flex align-items-start justify-content-end">
                        <b-badge v-if="product.available" class="badge-price" variant="success">
                          {{ $t('trip.hangar.available') }}
                        </b-badge>
                        <b-badge v-else class="badge-price" variant="warning">
                          {{ $t('trip.hangar.availability_subject_to_confirmation') }}
                        </b-badge>
                      </div>
                      <div>
                        <b-link
                          :to="{
                            name: 'hangar-detail',
                            params: {
                              provider_id: organization.id,
                              trip_id: tripData.id,
                              step_id: tripStep.id,
                              event_id: tripStepEvent.id,
                              product_id: product.product.id,
                              airport_id: tripStepEvent.airport.id,
                            },
                            query: {
                              start_at: paramsHangar.startAt,
                              end_at: paramsHangar.endAt,
                            },
                          }"
                          class="h3 text-underline"
                        >
                          {{ product.product.name | trans }}
                        </b-link>
                        <b-col class="col=12 pr-0 mt-1">
                          <div>
                            <h5 class="font-weight-bold">{{ $t('service.technical_informations.title') }}</h5>
                            <ul class="list-unstyled">
                              <li v-if="product.product.mainVariant.floorArea">{{ $t('service.technical_informations.net_floor_surface_for_rent') }} : {{ product.product.mainVariant.floorArea }} {{ $getMeasurementUnitItn('m') }}² </li>
                              <li v-if="product.product.mainVariant.maxDoorHeight">{{ $t('service.technical_informations.door_opening_height') }} : {{ product.product.mainVariant.maxDoorHeight }} {{ $getMeasurementUnitItn('m') }} </li>
                              <li v-if="product.product.mainVariant.maxDoorWidth">{{ $t('service.technical_informations.door_opening_width') }} : {{ product.product.mainVariant.maxDoorWidth }} {{ $getMeasurementUnitItn('m') }} </li>
                            </ul>
                            <ul>
                              <li v-for="attribute in product.product.mainVariant.variantAttributes" :key="attribute.id">
                                {{ attribute.attribute.name | trans }} :
                                <span v-if="attribute.inputValues">{{ attribute.inputValues | trans }}</span>
                                <span v-if="attribute.selectValue">{{ attribute.selectValue.name | trans }}</span>
                                <ul v-if="attribute.choiceValues">
                                  <li v-for="choice in attribute.choiceValues" :key="choice.id">
                                    {{ choice.name | trans }}
                                  </li>
                                </ul>
                              </li>
                            </ul>
                            <h5 v-if="$route.query.start_at || $route.query.end_at" class="font-weight-bold">{{ $t('trip.hangar.time_of_stay') }}</h5>
                            <ul v-if="$route.query.start_at || $route.query.end_at" class="list-unstyled">
                              <li v-if="$route.query.start_at">{{ $t('common.start_at') }} : {{ $moment.utc($route.query.start_at).format('ddd, MMM Do YYYY HH:mm [UTC] ') }}</li>
                              <li v-if="$route.query.end_at">{{ $t('common.end_at') }} : {{ $moment.utc($route.query.end_at).format('ddd, MMM Do YYYY HH:mm [UTC] ') }}</li>
                            </ul>
                          </div>
                        </b-col>

                      </div>
                    </div>
                    <div class="col-12 align-self-end">
                      <b-col class="d-flex align-items-center justify-content-end p-0">
                        <h4 class="item-price m-0">
                          <span v-if="!product.product.minPrice" class="h5">
                            {{ $t('trip.shop.price_on_quote') }}
                          </span>
                          <span v-else> {{ product.product.minPrice.displayPriceIncludingTaxes | priceFormat }} {{ $t('trip.hangar.incl_taxes') }}</span>
                        </h4>
                        <b-button v-if="!product.selected" class="btn-cart ml-1" variant="primary" @click="confirmation(product)">
                          <feather-icon class="mr-50" icon="ShoppingCartIcon" />
                          <span>{{ $t('trip.shop.add_to_cart') }}</span>
                        </b-button>
                        <b-button
                          v-else
                          :to="{
                            name: 'shop-checkout',
                            params: {
                              provider_id: organization.id,
                              trip_id: $route.params.trip_id,
                              step_id: tripStep.id,
                              event_id: $route.params.event_id,
                              airport_id: tripStepEvent.airport.id,
                            },
                          }"
                          class="btn-cart ml-1"
                        >
                          <feather-icon class="mr-50" icon="ShoppingCartIcon" />
                          <span>{{ $t('trip.shop.action.view_in_cart') }}</span>
                        </b-button>
                      </b-col>
                    </div>
                  </div>
                </b-col>
              </b-card>

              <div class="d-flex justify-content-center">
                <b-button v-b-toggle="'collapse_hangar-' + organization.id" :variant="`light`" @click="toggleHangarDetails('hangar' + organization.id)">
                  <font-awesome-icon :icon="'chevron-up'" class="" size="lg" />
                </b-button>
              </div>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <HangarConfirmation
      v-if="tripStepEvent"
      :confirmation-edition="confirmationEdition"
      :date="eventData"
      :selected-product="selectedProduct"
      :trip-step-event="tripStepEvent"
      @update:offer="postOffer($event)"
      @update:edition="confirmationEdition = $event"
    />
  </section>
</template>
<script>
import HangarSearch from '@/views/provider/hangar/HangarSearch.vue'
import number from '@/mixins/number'

import TripView from '@/views/trip/trip-view/TripView.vue'
import { fetchHangarProductRequest } from '@/request/globalApi/requests/apiRequests'
import HangarConfirmation from '@/views/provider/hangar/HangarConfirmation.vue'

export default {
  name: 'Hangar',
  components: {
    TripView,
    HangarConfirmation,
    HangarSearch,
  },
  mixins: [number],
  data() {
    return {
      tripData: {},
      tripStep: {},
      tripStepEvent: {},
      trip: {},
      time: '',
      aircraft: {},
      eventType: '',
      products: [],
      showLoading: false,
      tripStepLoading: true,
      eventData: {
        start: {
          date: '',
          time: '',
        },
        end: {
          date: '',
          time: '',
        },
      },
      organizationsIds: [],
      viewHangars: [],
      confirmationEdition: false,
      selectedProduct: null,
      paramsHangar: {
        organizationId: null,
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
        // SORTING
        nameOrder: null,
        // SEARCH
        searchQuery: null,
        categoriesIds: null,
        startAt: '',
        endAt: '',
        selectedOrganizationId: 0,
      },
    }
  },
  mounted() {
    this.APIFetchTrip()
  },
  methods: {
    async APIFetchTrip() {
      const tripId = Number(this.$route.params.trip_id)
      const trip = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: true })
      if (trip) {
        this.tripData = trip
        trip.tripSteps.forEach(tripStep => {
          if (!this.getCurrentTripStep(tripStep, tripStep.flightDeparture)) {
            this.getCurrentTripStep(tripStep, tripStep.flightArrival)
          }
        })
      }
    },
    getCurrentTripStep(leg, event) {
      if (event !== undefined) {
        Object.entries(event).forEach(([key, val]) => {
          if (key === 'id' && val === parseInt(this.$route.params.event_id, 10)) {
            this.tripStepEvent = event
            this.tripStep = leg
            this.tripStepLoading = false
            return true
          }
          return false
        })
      }
    },
    searchHangar() {
      this.showLoading = true
      this.eventData.start.time = this.$moment.utc(this.paramsHangar.startAt).format('HH:mm')
      this.eventData.start.date = this.$moment.utc(this.paramsHangar.startAt).format('YYYY-MM-DD')
      this.eventData.end.time = this.$moment.utc(this.paramsHangar.endAt).format('HH:mm')
      this.eventData.end.date = this.$moment.utc(this.paramsHangar.endAt).format('YYYY-MM-DD')

      this.APIFetchHangar()
    },
    APIFetchHangar() {
      this.organizationsIds = []
      this.products = []

      fetchHangarProductRequest(this.tripStepEvent.id, this.paramsHangar)
        .then(r => {
          const { products } = r.data
          this.products = products

          // TODO: use .filter()
          const Ids = []
          this.products.forEach(element => {
            if (!Ids.includes(element.product.organization.id)) {
              Ids.push(element.product.organization.id)
              this.viewHangars[element.product.organization.id] = false
            }
          })
          Ids.forEach(id => {
            const found = this.products.find(product => product.product.organization.id === id)
            this.organizationsIds.push(found.product.organization)
          })
        })
        .finally(() => {
          this.showLoading = false
        })
        .catch(() => {
          this.products = []
        })
    },
    confirmation(product) {
      this.selectedProduct = product.product
      this.confirmationEdition = true
    },
    postOffer() {
      // TODO: Use find() instead of forEach()
      this.products.forEach(product => {
        if (this.selectedProduct.id === product.product.id) {
          product.selected = true
        }
      })
    },
    toggleHangarDetails(organizationId) {
      const hangarFound = this.viewHangars.includes(organizationId)
      if (hangarFound) {
        this.viewHangars = this.viewHangars.filter(organization => organization !== organizationId)
      } else {
        this.viewHangars.push(organizationId)
      }
    },
  },
}
</script>
<style scoped></style>
