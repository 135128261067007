import globalApi from '@/request/globalApi/globalApi'

// PRODUCTS
export const fetchOrganizationProductsRequest = (tripStepEventId, organizationId, queryParams) => globalApi.get(`api/${tripStepEventId}/organization/${organizationId}/product`, { params: queryParams })

export const fetchOrganizationProductRequest = (tripStepEventId, organizationId, productId) => globalApi.get(`api/${tripStepEventId}/organization/${organizationId}/product/${productId}`)

// MANDATORY PACKAGE
export const fetchMandatoryPackageRequest = (tripStepEventId, organizationId, queryParams) => globalApi.get(`api/${tripStepEventId}/organization/${organizationId}/mandatory-package-product`, { params: queryParams })

export const fetchMandatoryPackageProductDetailRequest = (tripStepEventId, organizationId, productId, queryParams) => globalApi.get(`api/${tripStepEventId}/organization/${organizationId}/package-product/${productId}`, { params: queryParams })

// HANGAR
export const fetchHangarProductDetailRequest = (tripStepEventId, organizationId, productId, queryParams) => globalApi.get(`api/${tripStepEventId}/organization/${organizationId}/hangar-product/${productId}`, { params: queryParams })

export const fetchHangarProductRequest = (tripStepEventId, queryParams) => globalApi.get(`api/${tripStepEventId}/hangar-product`, { params: queryParams })

export const fetchInfoForHangarRequest = tripStepEventId => globalApi.get(`api/${tripStepEventId}/informations-for-hangar`)

// FUEL

export const fetchFuelVariantPriceRequest = (tripStepEventId, queryParams) => globalApi.get(`api/${tripStepEventId}/fuel-variant-price`, { params: queryParams })

export const fetchFuelVariantPriceDetailRequest = (tripStepEventId, fuelVariantPriceId, queryParams) => globalApi.get(`api/${tripStepEventId}/fuel-variant-price/${fuelVariantPriceId}`, { params: queryParams })

export const patchFuelVariantPriceQuotationRequest = (tripStepEventId, fuelVariantPriceId, data) => globalApi.patch(`api/${tripStepEventId}/fuel-variant-price/${fuelVariantPriceId}/quotation`, data)
