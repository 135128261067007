var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex flex-wrap match-height"},[_c('div',{staticClass:"col-12 col-md-6 pl-0"},[_c('b-card',[_c('ul',{staticClass:"list-unstyled"},[_c('li',{staticClass:"mb-1"},[_c('font-awesome-icon',{attrs:{"icon":"map-marked-alt"}}),_vm._v(" "+_vm._s(_vm.trip.name)+" ")],1),_c('li',{staticClass:"mb-1"},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}}),_vm._v(" "+_vm._s(_vm.$moment.utc(_vm.time).format('ddd, MMM Do YYYY HH:mm'))+" "+_vm._s(_vm.$t('common.utc'))+" ")],1),_c('li',{staticClass:"mb-1"},[_c('font-awesome-icon',{attrs:{"icon":"plane"}}),_vm._v(" "+_vm._s(_vm.aircraft.model)+" ")],1),_c('li',{staticClass:"mb-1"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('trip.hangar.footprint'))+" : "+_vm._s(_vm.aircraft.footPrint)+" "+_vm._s(_vm.$getMeasurementUnitItn('m'))+"² ")])]),_c('li',{staticClass:"mb-1"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('trip.hangar.height'))+" : "+_vm._s(_vm.aircraft.height)+" "+_vm._s(_vm.$getMeasurementUnitItn('m'))+" ")])]),_c('li',{staticClass:"mb-1"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('trip.hangar.wingspan'))+" : "+_vm._s(_vm.aircraft.wingspan)+" "+_vm._s(_vm.$getMeasurementUnitItn('m'))+" ")])])])])],1),_c('div',{staticClass:"col-12 col-md-6 p-0"},[_c('b-card',[_c('validation-observer',{ref:"dateFilterHangar",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"formEventRules",staticClass:"d-flex flex-wrap p-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{staticClass:"validation-required col-7 p-0",attrs:{"name":_vm.$t('common.start_at'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-1",attrs:{"label":_vm.$t('common.start_at'),"label-for":"arrival-date"}},[_c('b-form-datepicker',{attrs:{"id":"arrival-date","max":_vm.eventData.end.date,"state":errors.length > 0 ? false : null,"placeholder":"","size":"md"},model:{value:(_vm.eventData.start.date),callback:function ($$v) {_vm.$set(_vm.eventData.start, "date", $$v)},expression:"eventData.start.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{staticClass:"validation-required col-5 pr-0",attrs:{"name":_vm.$t('trip.event.eta_utc'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('trip.event.hour_utc'),"label-for":"arrival-time"}},[_c('b-form-timepicker',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"","size":"md"},model:{value:(_vm.eventData.start.time),callback:function ($$v) {_vm.$set(_vm.eventData.start, "time", $$v)},expression:"eventData.start.time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.eventData.start.date && _vm.eventData.start.time && _vm.tripStepEvent.airport)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('common.local_time'))+" : "+_vm._s(_vm.$moment .utc(_vm.eventData.start.date + ' ' + _vm.eventData.start.time) .utcOffset(_vm.tripStepEvent.airport.timeZone) .format('ddd, MMM Do YYYY HH:mm'))+" ")]):_vm._e()],1)]}}],null,true)}),_c('validation-provider',{staticClass:"validation-required col-7 p-0",attrs:{"name":_vm.$t('common.end_at'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-1",attrs:{"label":_vm.$t('common.end_at'),"label-for":"departure-date"}},[_c('b-form-datepicker',{attrs:{"id":"departure-date","min":_vm.eventData.start.date,"state":errors.length > 0 ? false : null,"placeholder":"","size":"md"},model:{value:(_vm.eventData.end.date),callback:function ($$v) {_vm.$set(_vm.eventData.end, "date", $$v)},expression:"eventData.end.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{staticClass:"validation-required col-5 pr-0",attrs:{"name":_vm.$t('trip.event.etd_utc'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('trip.event.hour_utc'),"label-for":"departure-time"}},[_c('b-form-timepicker',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"","size":"md"},model:{value:(_vm.eventData.end.time),callback:function ($$v) {_vm.$set(_vm.eventData.end, "time", $$v)},expression:"eventData.end.time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.eventData.end.date && _vm.eventData.end.time && _vm.tripStepEvent.airport)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('common.local_time'))+" : "+_vm._s(_vm.$moment .utc(_vm.eventData.end.date + ' ' + _vm.eventData.end.time) .utcOffset(_vm.tripStepEvent.airport.timeZone) .format('ddd, MMM Do YYYY HH:mm'))+" ")]):_vm._e()],1)]}}],null,true)}),_c('div',{staticClass:"d-flex justify-content-end col-12 p-0 mt-1"},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-button',{attrs:{"loading":"true"},on:{"click":function($event){return _vm.search()}}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}}),_vm._v(" "+_vm._s(_vm.$t('action.search'))+" ")],1)],1)],1)],1)]}}])})],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }